import {
  IonSelect,
  IonSelectOption,
  IonIcon,
  IonText,
  isPlatform,
  useIonAlert,
  useIonLoading,
  useIonToast,
  useIonModal,
} from '@ionic/react';
import {useEffect, useState} from 'react';
import MWRatingFull from '../../../Components/MWRating/MWRatingFull';
import MWButton from '../../../Components/MWButton/MWButton';
import {addToCart, subscriptionsAdd} from '../../Cart/CartStore';
import {checkmark, reload} from 'ionicons/icons';
import {ProductBuyBarProps, VariantType} from '../types';
import {useStoreState} from 'pullstate';
import {UserStore} from '../../User/UserStore';
import dayjs from 'dayjs';
import CartModal from '../../Cart/CartModal';
import SubscriptionModal from '../../Subscriptions/SubscriptionsModal';

const ProductBuyBarWithQuantityButton = ({
                                           className,
                                           ...props
                                         }: ProductBuyBarProps) => {
  const [presentAlert] = useIonAlert();
  const [product, setProduct] = useState<any>();
  const [currentVariantIndex, setCurrentVariantIndex] = useState<number | null>(
    0
  );
  const [selectedVariant, setSelectedVariant] = useState<VariantType | null>(
    null
  );
  const [basePrice, setBasePrice] = useState<number | null>(null);
  const [totalInventory, setTotalInventory] = useState<number | null>(null);
  const [selectedVariantInventory, setSelectedVariantInventory] = useState<
    number | string
  >('...');
  const [selectedQty, setSelectedQty] = useState<number>(1);
  const [presentToast, dismissToast] = useIonToast();
  const [showLoading, dismissLoading] = useIonLoading();
  const userState = useStoreState(UserStore);
  const [presentCartModal, dismissCartModal] = useIonModal(CartModal, {
    onDismiss: () => {
      dismissCartModal();
    }
  });
  const [presentSubscriptionModal, dismissSubscriptionModal] = useIonModal(SubscriptionModal, {
    onDismiss: () => {
      dismissSubscriptionModal();
    }
  });

  useEffect(() => {
    setProduct(props.product);
    setSelectedQty(1);

    if (product && currentVariantIndex !== null) {
      setSelectedVariant(product.variants[currentVariantIndex]);
    }

    if (product && selectedVariant) {
      const basePrice = selectedVariant.salePrice
        ? selectedVariant.salePrice
        : selectedVariant.price;
      let totalInventory = 0;

      setBasePrice(basePrice);

      if (product.variants.length > 1) {
        product.variants.forEach((variant: any) => {
          totalInventory += variant.inventory;
        });
      } else {
        totalInventory = selectedVariant.inventory;
      }

      setTotalInventory(totalInventory);
      setSelectedVariantInventory(selectedVariant.inventory);
    }
  }, [
    currentVariantIndex,
    product,
    props.product,
    selectedVariant,
    selectedVariantInventory,
  ]);

  /**
   * Subscribe to a Variant
   */
  const subscribe = async () => {
    if (product && product.id && selectedVariant && selectedVariant.id) {
      await subscriptionsAdd(product.id, selectedVariant.id, 1);
      presentSubscriptionModal();

      product.variants.forEach((variant: any) => {
        if (variant.id === selectedVariant.id) {
          variant.isSubscribed = true;
        }
      });
    }
  };

  /**
   * Add this to the Cart
   */
  const localAddToCart = async () => {
    if (product && product.id) {
      if ([13369, 13370, 13371, 13368, 13368, 7209].includes(product.id)) {
        presentAlert(
          'April Fools! Just having some fun today with wacky products. Share this product with your friends and see if you can convince them to buy it, too.',
          [
            {
              text: 'Close',
            },
          ]
        ).then();
      } else {
        showLoading('Adding to your cart...').then();
        await addToCart({
          productId: product.id,
          qty: selectedQty,
          variantId:
            selectedVariant && selectedVariant.id ? selectedVariant.id : 0,
        }).then();
        dismissLoading().then();

        const buttons = isPlatform('mobile')
          ? [
            {
              text: 'View Cart',
              handler() {
                presentCartModal();
                dismissToast();
              },
            },
          ]
          : [];
        presentToast({
          keyboardClose: true,
          position: 'top',
          duration: 1700,
          cssClass: 'mw-toast',
          animated: true,
          buttons: buttons,
          message: `Added ${product.title} to Cart `,
        }).then();
      }
    }
  };

  const handleVariantChange = (variantIndex: number) => {
    const pickedVariant = product.variants[variantIndex];

    setCurrentVariantIndex(variantIndex);
    setSelectedVariant(pickedVariant);
    setSelectedVariantInventory(pickedVariant.inventory);
  };

  return (
    <>
      <div className={`ProductBuyBar ProductBuyBarQty relative ${className}`}>
        <div
          className={`flex flex-row items-center pb-1 justify-between ${
            props.priceClass || ''
          }`}
        >
          <div className='flex items-center space-x-2'>
            <div aria-label='Product Price' className='font-semibold flex-stiff'>
              <span className='text-red-800 '>
                ${parseFloat(`${basePrice}`).toFixed(2)}
              </span>
            </div>

            {product &&
              product.variants.length &&
              selectedVariant &&
              selectedVariant.salePrice && (
                <IonText className='line-through opacity-50 flex-stiff'>
                  ${parseFloat(`${selectedVariant.price}`).toFixed(2)}
                </IonText>
              )}

            <div className='flex-fill'>
              {(totalInventory === 0 || selectedVariantInventory === 0) && (
                <IonText className='text-sm text-red-500 font-black'>
                  SOLD OUT
                </IonText>
              )}
            </div>

            {product && (product.ratingCount >= 3 || (product.ratingCount > 0 && product.ratingCount <= 2 && product.avgRating >= 3.5)) && (
              <MWRatingFull className='pb-0.5' hideValue={true} rating={product.avgRating}/>
            )}
          </div>

          {product && product.discountNotes && (
            <span
              className={`text-xs md:text-sm font-bold uppercase text-right ${product.ribbonType === 'staff-flash-sale' ? 'text-pink-600' : ''}`}
              style={product.ribbonType === 'staff-insane-weekly-deal' ? {color: '#8C3481'} : {}}>{product.discountNotes}</span>
          )}
        </div>

        {product && product?.isPreOrder && selectedVariant && selectedVariant.preOrderDate &&
          <div className='pb-2 pt-0'>
            <p className='font-medium text-sm text-black'>
              Arrives: <span
              className='text-red-500'>{dayjs(selectedVariant.preOrderDate.split('T')[0]).format('MM/DD/YYYY')}</span>

              {product.variants.length > 1 &&
                <span>&nbsp; (with other options)</span>
              }
            </p>
          </div>
        }

        <div
          className={`flex flex-col space-y-3 md:space-y-0 md:space-x-3 md:flex-row`}
        >
          {product?.variants.length > 1 && (
            <IonSelect
              className='flex w-full text-sm font-semibold text-green-600 border border-green-600 rounded-md shadow-md focus:ring-2 ring-green-600 h-11 line-clamp-1 border-opacity-30'
              value={currentVariantIndex}
              placeholder='Options'
              interface='action-sheet'
              onIonChange={(v: any) => {
                handleVariantChange(v.detail.value);
              }}
            >
              {product.variants.map((variant: VariantType, index: number) => {
                return (
                  <IonSelectOption
                    key={index}
                    value={index}
                    className='flex items-center divide-x-2'
                  >
                    {variant.title}, (${(variant.salePrice ? variant.salePrice : variant.price).toFixed(2)})

                    {product.isPreOrder &&
                      ` - ${dayjs(variant.preOrderDate.split('T')[0]).format('MM/DD/YYYY')}`
                    }
                  </IonSelectOption>
                );
              })}
            </IonSelect>
          )}

          {/* Buy Now and Subscribe Buttons  */}
          <div className='row flex items-center space-x-2 w-full filler'>
            {!!(
              product &&
              totalInventory !== null &&
              totalInventory > 0 &&
              selectedVariant &&
              selectedVariantInventory &&
              selectedVariantInventory > 0
            ) && (
              <IonSelect
                placeholder={`QTY: ${selectedQty}`}
                className='justify-center w-full max-w-100px p-2 bg-gray-200 rounded-lg h-11 text-sm md:text-base'
                interface='action-sheet'
                style={{'--placeholder-color': '#0a0a0a'}}
                onIonChange={(e: any) => {
                  setSelectedQty(e.detail.value);
                }}
              >
                {Array.from(Array(selectedVariantInventory)).map(
                  (item, index) => {
                    item = index + 1;

                    return (
                      <IonSelectOption key={index} value={item}>
                        {item}
                      </IonSelectOption>
                    );
                  }
                )}
              </IonSelect>
            )}

            {!!product &&
              !!(totalInventory && totalInventory > 0) &&
              !!(selectedVariantInventory && selectedVariantInventory > 0) && (
                <MWButton
                  title='Add to Cart'
                  onClick={localAddToCart}
                  bg-gray-200
                  className='justify-center w-full text-white bg-green-600 rounded-lg h-11 text-sm md:text-base'
                >
                  Add to Cart
                </MWButton>
              )}

            {
              (totalInventory && totalInventory <= 0)
              && (
                <MWButton
                  title='Watch Product'
                  onClick={localAddToCart}
                  bg-gray-200
                  className='justify-center w-full text-white bg-gray-600 rounded-lg h-11 text-sm md:text-base'
                >
                  <div className='flex-col'>
                    <div className='text-xs opacity-50 leading-none'>
                      Not available
                    </div>
                    <div className='text-base leading-none'>+ Watch List</div>
                  </div>
                </MWButton>
              )}

            {userState.isGuest !== true && product && product?.isPreOrder !== true &&
              product.variants.find((v: any) => v.isSubscribed) &&
              !props.hideDetails && (
                <MWButton
                  title='Change subscription status'
                  onClick={() => presentSubscriptionModal()}
                  className='justify-center w-full font-bold text-black bg-gray-200 rounded-lg h-11 text-sm md:text-base'
                >
                  Subscribed
                  <IonIcon icon={checkmark}/>
                </MWButton>
              )}

            {userState.isGuest !== true && product && product?.isPreOrder !== true &&
              !product.variants.find((v: any) => v.isSubscribed) &&
              !props.hideDetails && (
                <MWButton
                  title='Subscribe to this Product'
                  onClick={() => subscribe()}
                  className='justify-center w-full font-bold text-green-800 bg-green-200 rounded-lg h-11 text-sm md:text-base'
                >
                  Subscribe <IonIcon className='ml-1' icon={reload}/>
                </MWButton>
              )}
          </div>
        </div>
      </div>

      {!props.hideDetails && (
        <div className='py-2 px-4'>
          <p className='text-gray-400 bg-transparent text-xs text-center'>
            {product && product.previousOrderCount > 0 && (
              <span>
                You've ordered this {product.previousOrderCount}{' '}
                {product.previousOrderCount > 1 ? 'times' : 'time'}
              </span>
            )}

            {product &&
              !product.previousOrderCount &&
              !product.previousVendorOrderCount && (
                <span>You've never ordered from this vendor before.</span>
              )}

            {product &&
              !product.previousOrderCount &&
              product.previousVendorOrderCount !== 0 && (
                <span>
                  You've never ordered this product, but you've ordered{' '}
                  {product.previousVendorOrderCount} time(s) from this vendor.
                </span>
              )}
          </p>
        </div>
      )}
    </>
  );
};

export default ProductBuyBarWithQuantityButton;
