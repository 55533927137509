import {
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  useIonAlert,
  useIonLoading,
  useIonModal,
  useIonRouter,
} from '@ionic/react';
import dayjs from 'dayjs';
import {useEffect, useState} from 'react';
import Map from '../../Components/Map/Map';
import MWButton from '../../Components/MWButton/MWButton';
import Qty from '../../Components/Qty/Qty';
import CartItem from '../Cart/CartItemClass';
import {bulkCartAdd} from '../Cart/CartStore';
import Order from './OrderClass';
import {editOrder} from "../../Api/api.orders";
import IFrameModalController from "../../Components/IframeModal/IframeModalController";
import {UserStore} from "../User/UserStore";
import {setVendor} from '../Vendor/VendorStore';
import Vendor from '../Vendor/VendorClass';
import {ProductModal} from '../Product/ProductModal';
import VendorModal from '../Vendor/VendorModal';
import {loadProduct} from '../Product/ProductStore';


type OrderViewProps = {
  order: Order;
  editMode: boolean;
  onToggleEditMode: Function;
  dismissModal: Function;
};
const OrderView = ({...props}: OrderViewProps) => {
  const [order, setOrder] = useState<Order>(props.order);
  const [localOrder, setLocalOrder] = useState<Order | undefined>(undefined);
  const [items, setItems] = useState<Array<CartItem>>([]);
  const [showAlert] = useIonAlert();
  const [showLoading, stopLoading] = useIonLoading();
  const [editOrderState, setEditOrderState] = useState(false);
  const [showReportProblem, setShowReportProblem] = useState(false);
  const [disableEdit, setDisableEdit] = useState(false);
  const router = useIonRouter();

  const [presentProductModal, dismissProductModal] = useIonModal(ProductModal, {
    onDismiss: () => {
      dismissProductModal();
    }
  });
  const [presentVendorModal, dismissVendorModal] = useIonModal(VendorModal, {
    onDismiss: () => {
      dismissVendorModal();
    },
    router: router,
  });

  /**
   * Change Quantity of an item
   * @param item
   * @param dir
   */
  const changeQty = (item: CartItem, dir: 'add' | 'subtract') => {
    if (dir === 'add') showAlert('To add an additional item, create a new order.').then();
    if (dir === 'subtract')
      item.quantity = item.quantity ? item.quantity - 1 : 0;
    let newItems = items.map((ci) => {
      return ci.id === item.id ? item : ci;
    });
    setItems(newItems);
  };


  /**
   * Save Order Edits
   * This will submit the order
   * to the api with the updated
   * item quantities
   */
  const saveOrderEdits = async () => {
    setDisableEdit(true);
    if (localOrder) {
      showLoading('Submitting Order...').then();

      const updatedOrder = new Order(localOrder);

      updatedOrder.items = items;
      let editReturn = await editOrder(updatedOrder);

      if (Object.keys(editReturn.items).length === 0) {
        changeStateWithReturn(editReturn);
        showAlert('Order has been deleted!').then();
        props.dismissModal();
      } else {
        changeStateWithReturn(editReturn);
        showAlert('Order changes submitted!').then();

      }

      stopLoading().then();
      setDisableEdit(false);
      props.onToggleEditMode();
      setEditOrderState(false);
    }
  };

  const changeStateWithReturn = (updatedOrder: Order) => {
    setOrder(new Order(updatedOrder));
    setLocalOrder(new Order(updatedOrder));
    let allOrderItems = updatedOrder.items;
    updatedOrder.addOns.forEach(addOnOrder => {
      addOnOrder.items.forEach(addOnItem => {
        let item = new CartItem(addOnItem);
        allOrderItems.push(item)
      })

    });

    setItems(allOrderItems);
    setEditOrderState(true);
  };

  const reorderItems = () => {
    let itemVariantIds: { variantId: number; quantity: number, fromBundleId: number | null }[] = [];

    items.forEach((item) => {
      if (multipleVariants(item.product.variant.id, itemVariantIds)) {
        itemVariantIds.map(o => {
          if (o.variantId === item.product.variant.id) {
            o.quantity = o.quantity + item.quantity;
            return true;
          }
          return false;
        });
      } else {
        itemVariantIds.push({
          variantId: item.product.variant.id,
          quantity: item.quantity,
          fromBundleId: null,
        });
      }
    });

    showLoading('Adding to your cart...').then();

    bulkCartAdd(itemVariantIds).then(() => {
      stopLoading().then();
      props.dismissModal();
    });


  };

  function multipleVariants(id: number, itemVariantIds: any) {
    return itemVariantIds.some(function (e: any) {
        return e.variantId === id;
      }
    );
  }

  const handleOpenModal = async (product: any) => {
    await loadProduct(product).then((res: any) => {
      if (res) {
        presentProductModal();
      } else {
        showAlert(
          'There was an error loading this product. Please try again later.'
        );
      }
    });
  }

  useEffect(() => {
    if (!editOrderState) {
      const updatedOrder = new Order(order);
      setLocalOrder(updatedOrder);
      let allOrderItems = updatedOrder.items;

      order.addOns.forEach(addOnOrder => {
        addOnOrder.items.forEach(addOnItem => {
          let item = new CartItem(addOnItem);
          allOrderItems.push(item)
        })

      });
      setItems(allOrderItems);
    }
  }, [order, props.editMode, editOrderState]);

  if (localOrder) {
    const formattedAddress = localOrder.deliveryAddress?.address === undefined ?
      `${localOrder.pickupAddress?.address}, ${localOrder.pickupAddress?.city}, ${localOrder.pickupAddress?.state} ${localOrder.pickupAddress?.zipCode}`
      :
      `${localOrder.deliveryAddress?.address}, ${localOrder.deliveryAddress?.city}, ${localOrder.deliveryAddress?.state} ${localOrder.deliveryAddress?.zipCode}`;

    return (
      <article className='order-view' aria-label='Order Details'>
        <IonList>
          {/* Map, Order and Address */}
          <Map address={formattedAddress || ''} className='mb-4 h-30'/>
          <IonItem>
            <div slot='end'>📍</div>
            <main className='w-full py-3 text-sm md:text-base'>
              <h2>
                Ordered{' '}
                <span className='font-bold'>
                                {dayjs(localOrder.created).format('ddd MMM D YYYY')}
                              </span>
              </h2>
              <address className='not-italic text-gray-600'>
                {formattedAddress}
              </address>
            </main>
          </IonItem>

          {/*  Show the Cart Items  */}
          <IonListHeader className='px-0'>
            <div className='px-5 text-base font-bold'>Items</div>
          </IonListHeader>
          {items.map((item: CartItem, index: number) => {
            return (
              <IonItem
                key={index}
                lines={index + 1 === items.length ? 'none' : 'inset'}
              >
                <IonLabel className='ion-text-wrap'>
                  <div
                    onClick={() => {
                      if (item.isPublished) {
                        handleOpenModal(item.product).then();
                      } else {
                        showAlert(
                          'This product is currently unpublished'
                        ).then();
                      }
                    }}
                    className='mb-1 text-lg font-semibold leading-tight text-green-600 line-clamp-2 cursor-pointer'>
                    {item.product.title}
                    {(order.status === 'closed' && !item.isPublished) && (
                      <span className='text-red-500'> - Unavailable</span>
                    )}
                    {(order.status === 'closed' && item.isPublished) && item.product.variant.inventory < 1
                      && (
                        <span className='text-red-500'> - Out of stock</span>
                      )}
                  </div>
                  <p
                    onClick={() => {
                      setVendor(item?.vendor as Vendor);
                      presentVendorModal();
                    }}
                    className='mt-1 text-gray-600'>
                    {item.vendor.name}
                  </p>
                </IonLabel>

                {/* Price and QTY */}
                <div
                  slot='end'
                  aria-label='price'
                  className='text-sm text-right'
                >
                  {/* Qty Controls  */}
                  {!props.editMode && (
                    <span className='text-gray-500'>Qty: {item.quantity}</span>
                  )}
                  {props.editMode && (
                    <span className='text-gray-500'>
                    <Qty
                      onLess={() => {
                        changeQty(item, 'subtract');
                      }}
                      onMore={() => {
                        changeQty(item, 'add');
                      }}
                      value={item.quantity}
                    />
                  </span>
                  )}

                  {/* Price  */}
                  <div className='text-base font-semibold text-gray-700'>
                    ${(item.product.variant.price * item.quantity).toFixed(2)}
                  </div>
                </div>
              </IonItem>
            );
          })}
          <div className='h-4 bg-gray-200'></div>
          <IonItem>
            <IonLabel>Fees</IonLabel>
            <div
              slot='end'
              aria-label='price'
              className='text-sm font-semibold text-gray-600'
            >
              ${(order.processingFee || 0).toFixed(2)}
            </div>
          </IonItem>
          <IonItem>
            <IonLabel>Tax</IonLabel>
            <div
              slot='end'
              aria-label='price'
              className='text-sm font-semibold text-gray-600'
            >
              ${(order.tax || 0).toFixed(2)}
            </div>
          </IonItem>
          {(order.discountAmount !== null) && (
            <IonItem>
              <IonLabel>Discount</IonLabel>
              <div
                slot='end'
                aria-label='price'
                className='text-sm font-semibold text-gray-600'
              >
                -${(order.discountAmount || 0).toFixed(2)}
              </div>
            </IonItem>
          )}
          <IonItem>
            <IonLabel>Tip</IonLabel>
            <div
              slot='end'
              aria-label='price'
              className='text-sm font-semibold text-gray-600'
            >
              ${localOrder.tip.toFixed(2)}
            </div>
          </IonItem>

          <IonItem className='text-lg' lines='none'>
            <IonLabel className='font-bold'>Order Total</IonLabel>
            <div
              slot='end'
              aria-label='price'
              className='font-semibold text-gray-600'
            >
              ${localOrder.calculateTotal}
            </div>
          </IonItem>
        </IonList>

        {props.editMode && (
          <div className='flex items-center justify-center w-full p-4 space-x-2'>
            <MWButton
              title='Update Order'
              disabled={disableEdit}
              className='w-full bg-green-500 text-white'
              md
              rounded
              onClick={saveOrderEdits}
            >
              Update Order
            </MWButton>
          </div>
        )}

        <div className='md:flex items-center justify-center w-full'>
          <MWButton
            onClick={() => {
              setShowReportProblem(true);
            }}
            title='Get Help'
            sm
            rounded
            className='bg-gray-100 text-black w-full md:w-auto'
          >
            Need Help?
          </MWButton>
          <IFrameModalController
            onDismiss={() => {
              setShowReportProblem(false);
            }}
            title='Report a Problem'
            url={'https://admin.marketwagon.com/problems/?uEmail=' + UserStore.getRawState().email + '&orderId=' + localOrder.id}
            open={showReportProblem}

          />

          <MWButton
            title='Reorder'
            disabled={props.editMode}
            sm
            rounded
            className=' bg-green-500 text-white w-full md:w-auto'
            onClick={() => {
              reorderItems();
            }}
          >
            Reorder
          </MWButton>
        </div>
      </article>
    );
  } else {
    return <div>'Loading...'</div>;
  }
};

export default OrderView;
